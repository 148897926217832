.accordion-button {
  background-color: transparent !important;
  color: #000;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:active {
  outline: none;
}

.accordion-button:not(.collapsed) {
  color: #000 !important;
}
