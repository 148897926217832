.walks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
}
.walk {
  position: relative;
  padding-left: 50px;
  margin-bottom: 20px;
}
.walk::before {
  content: attr(data-walk);
  position: absolute;
  left: 10px;
  top: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ddd;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  z-index: 0;
}
.walk.active::before {
  background-color: #4caf50;
}
.walk.active + .walk.active::before {
  background-color: #4caf50;
}
.walk.active + .walk.active::after {
  content: "";
  position: absolute;
  left: 24px;
  top: -20px;
  width: 2px;
  height: calc(100% + 1px);
  background-color: #4caf50;
  z-index: 0;
}
.walk-title {
  font-weight: bold;
}
