@use "../../../dx-variables.scss" as *;

.list-title {
  font-size: 16px;
  margin-right: 1em;
  margin-left: 1em;
  border-radius: 10px;
  //box-shadow: 8px 8px 2px 1px rgba(0, 0, 255, .2);
  padding-top: 1em;
  padding-bottom: 0.1em;
  font-weight: bold;
  cursor: pointer;
}

#kanban {
  white-space: nowrap;
  font-family: "Roboto" !important  ;
}

.bgc-title-new {
  background-color: rgb(176 235 242);
}

.bgc-title-assigned {
  background-color: rgb(254 230 230);
}

.bgc-title-inprogress {
  background-color: rgb(254 246 176);
}

.bgc-title-finished {
  background-color: rgb(176 254 176);
}

.list {
  border-radius: 4px;
  margin: 10px;
  background-color: "white";
  // background-color: var(--background-gray-color);
  display: inline-block;
  vertical-align: top;
  white-space: inherit;

  .add-task {
    padding: 10px;

    .dx-icon,
    .dx-button-text {
      color: var(--accent-color);
    }
  }
}

.sortable-cards {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  
  gap: 10px;
}

.scrollable-list {
  width: 100%; 
}

.scrollable-list .dx-scrollable-scroll-content {
  background-color: hsl(222, 67%, 12%) !important;
  height: 100%;
  width: 100%;
}

