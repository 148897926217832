/* .dx-scheduler-cell-sizes-horizontal {
  width: 50px !important;
} */

.dx-scheduler-header-tables-container {
  flex: 1;
}
.dx-scheduler-date-table-scrollable {
  flex: 1;
}
.dx-scheduler-date-table-container {
  width: 100%;
}
.dx-scheduler-date-table-scrollable-content {
  width: 100%;
}
.dx-scheduler-cell-sizes-horizontal {
  width: 60px !important;
}
.dx-scheduler-group-header {
  padding: 0 !important;
  width: 120px !important;
}
.dx-scheduler-appointment-content {
  padding: 0 !important;
}

/* Hide the seconde header row from table */
.dx-scheduler-header-panel .dx-scheduler-header-row:nth-child(2) {
  display: none;
}