@use "../../../dx-variables.scss" as *;

:root {
  --low-priorety-color: #dfb32f;
  --normal-priorety-color: #6fbaca;
  --high-priorety-color: #d9534f;
}

@mixin priority($priorety-color) {
  .card-priority {
    background: $priorety-color;
  }

  span.priority {
    color: $priorety-color;
  }
}

.fire {
  /* Primer resplandor */
  text-shadow: 0px 0px 5px #fff, /* Parte de luz */ 4px 2px 12px #dcb500,
    -4px 2px 12px #dcb500, /* Subida del fuego */ 0px -1px 2px #ff7a00,
    0px -5px 3px #ff6300, 0px -8px 5px #ff4400,
    /* Azuleo de la llama */ 0px -10px 10px #0a7bff;
  /* Animación */
  animation: fuego 1s ease-in-out infinite;
}

.bgc-high {
  background-color: rgb(251 138 138);
  //background: radial-gradient(#ff0000 0%, #ff7a7a 0%);
}

.bgc-timer-high {
  background-color: rgb(250 84 84);
}

.bgc-normal {
  background-color: rgb(253 213 139);
}
.bgc-timer-normal {
  background-color: rgb(251 164 3);
}
.bgc-low {
  background-color: rgb(120 223 219);
}

.bgc-timer-low {
  background-color: rgb(54 207 201);
}

.bgc-default {
  background-color: rgb(231 231 231);
}

.bgc-timer-default {
  background-color: rgb(184 184 184);
}

.kanban-card {
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  border-radius: 10px;
}

.card-priority {
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  width: 4px;
}

.card-wrapper {
  padding: 10px;

  .edit-button {
    position: absolute;
    right: 10px;
    font-size: 18px;
    opacity: 0.87;
  }

  &.priority-low {
    @include priority(var(--low-priorety-color));
  }

  &.priority-normal {
    @include priority(var(--normal-priorety-color));
  }

  &.priority-high {
    @include priority(var(--high-priorety-color));
  }
}

.card-content {
  padding-left: 14px;

  span.priority {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }

  span.date {
    font-size: 12px;
    line-height: 14px;
    opacity: 0.87;
  }
}

.card-subject {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.card-assignee {
  font-size: 12px;
  line-height: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
