.view {
  margin: auto;
  width: 600px;
}

.wrapper {
  position: relative;
  overflow: auto;
  /* border: 1px solid black; */
  white-space: nowrap;
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
}

.first-col {
  width: 75px;
  min-width: 75px;
  max-width: 150px;
  left: 0px;
}

.second-col {
  width: 75px;
  min-width: 75px;
  max-width: 100px;
  left: 50px;
}