
.favorites-container {
    margin-left: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    // width: 150px;
    // overflow-x: hidden;
    // border: 2px solid white;

    .favorites-item {
        // border: 2px solid white;
        color: white;
        font-size: 20px;
        &:hover {
            color: #cecece;
        }
    }
}