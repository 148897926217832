@import "../../themes/generated/variables.base.scss";

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
  padding: 10px 1rem;
  background: #F0F5FA;
  color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  width: 100%;

  .footer-item {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    .footer-item-icon {
      font-size: 0.8rem;
    }

    .footer-item-text {
      font-size: 0.6rem;
    }
  }
}
