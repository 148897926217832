/* REBOOT */
iframe {
  border: 0;
}

figcaption,
figure,
main {
  display: block;
  margin: 0;
}

main {
  overflow: hidden;
}

img {
  max-width: 100%;
}

strong {
  font-weight: 400;
}

button:focus {
  outline: 0;
}

label {
  font-weight: 400;
}

/* END REBOOT */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --main-blue: #0B1634;
}


/* CUSTOM STYLE */
.header-toolbar {
  background-image: linear-gradient(to right, #0a1633, #22b4cf);
}

.dx-theme-material-typography .dx-swatch-additional,
.dx-theme-material-typography.dx-swatch-additional {
  background: #0a1633 !important;
}

#navigation-header {
  background: #0a1633 !important;
}

.header-component .dx-toolbar .dx-toolbar-item.menu-button>.dx-toolbar-item-content i {
  color: #fff !important;
}

.side-navigation-menu .menu-container .dx-treeview .dx-treeview-node-container .dx-treeview-node.dx-state-selected>.dx-treeview-item * {
  color: #fff !important;
}

.dx-item-content.dx-treeview-item-content {
  color: #b9c7d4 !important;
  margin-left: 20px;
}



.menu-container ul .dx-treeview-node-container-opened>.dx-treeview-node {
  margin-left: 20px
}

.menu-container i:after {
  content: "";
  position: absolute;
  top: 30px;
  left: 18px;
  width: 1px;
  height: calc(100% - 55px);
  background: rgba(185, 199, 212, .2)
}


/* HORIZONTAL */

.menu-container ul .dx-treeview-node-container-opened>.dx-treeview-node:after {
  content: "";
  position: absolute;
  top: 25px;
  left: 0px;
  width: 15px;
  height: 1px;
  background: rgba(185, 199, 212, .2)
}

.side-navigation-menu .menu-container .dx-treeview .dx-treeview-node .dx-treeview-item-content span {
  margin-left: 10px;
}

.side-navigation-menu .menu-container .dx-treeview .dx-treeview-node[aria-level="2"] .dx-treeview-item-content {
  padding: 0px !important
}

.full.container {
  width: 100% !important;
  max-width: 100% !important;
}

.container {
  max-width: 100% !important;
  padding: 0px !important;
}

main {
  width: 100%
}



.carousel-item {
  height: 100vh;
  min-height: 300px;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.dx-theme-material-typography a {
  color: #000 !important;
  text-decoration: none !important;
  font-weight: bold;
}

.btn-primary {
  color: #fff !important;
  background-color: #4099ff !important;
  ;
  border-color: #4099ff !important;
}

.dx-treeview-toggle-item-visibility-opened {
  color: #fff !important;
}

.dx-treeview-item-without-checkbox[aria-expanded="true"]>.dx-item>.dx-item-content>span,
.dx-treeview-item-without-checkbox[aria-expanded="true"]>.dx-item>.dx-item-content>i {
  color: #fff !important;

}

.logo {
  font-weight: 400;
  font-size: 28px;
  color: #fff;
}

.logo b {
  color: #0ff;
  font-size: 28px;
}

.resultContainer {
  background-color: #e3e3e3 !important;
}

.dx-texteditor-input,
.dx-texteditor-buttons-container {
  background: #fff !important;
}


/* FONT SIZES */
.dx-theme-material-typography h4 {
  font-size: 24px !important;
}

.dx-theme-material-typography h5 {
  font-size: 18px !important;
}

.dx-pager .dx-page-sizes .dx-selection,
.dx-pager .dx-pages .dx-selection {
  background: #4099ff !important;
}

.invalid {
  color: orangered !important;
}

#dataGrid .dx-datagrid-rowsview .dx-select-checkbox {
  display: inline-block;
}

#contentContainer .card-header,
#contentContainer .card-footer {
  background: #d2dce6 !important;
}

#contentContainer .titleContainer {
  background: #A0AABE !important;
}

.dx-header-row {
  background: #e6ebf0 !important;
}

.titleContainer h5 {
  font-weight: bold !important;
}

.dx-drawer-left .logo,
.dx-drawer-left .menu-container span {
  display: none;
}

.dx-drawer-left.dx-drawer-opened .logo,
.dx-drawer-left.dx-drawer-opened .menu-container span {
  display: inline-block !important;
}

.separatorTitle {
  font-weight: bold;
  color: #0a1633 !important;
}

.separatorLine {
  background-color: #0a1633;
  opacity: 1 !important;
  height: 1.7px;
  border: 0;
}

.form-group.required .control-label:after {
  content: "*";
  color: red;
}

.btn-blue-overline {
  background-color: transparent;
  color: var(--main-blue);
  border: 1px solid var(--main-blue) !important;
  border-radius: 15px
}

.btn-blue-overline-selected,
.btn-blue-overline:hover {
  background-color: var(--main-blue) !important;
  color: #F6FEFF !important;
}

.btn-blue-overline-selected:hover {
  border: 1px solid var(--main-blue) !important;
  background-color: transparent !important;
  color: var(--main-blue) !important;
}

.language-select .dx-button-text {
  color: white !important;
}

.required-asterisk {
  font-size: 15px;
}

.dx-page-sizes {
  float: right !important;
  white-space: nowrap !important;
}

.dx-pages {
  float: left !important
}

.formBackground {
  background: #F0F5FA !important;
}

.dx-row-lines.dx-selection {
  background: #2fc2dd5c !important;
}

.form-input {
  height: 40px
}

.text-blue {
  color: var(--main-blue) !important;
}

.dx-tabs {
  padding: 0 !important;
}

.dx-item.dx-tab {
  padding: 20px 16px !important;
  color: var(--main-blue) !important;
  font-size: 13px;
  background: #BEC8D2;
  border-bottom: 1px solid #ccc !important;
}

.dx-item.dx-tab.dx-state-hover,
.dx-item.dx-tab.dx-tab-selected {
  background: #E5EAEF;
}

.dx-item.dx-tab.dx-tab-selected::before {
  content: none !important;
}

.tab-form {
  padding: 2rem !important;
  background: #E3EBF3;
}

#form-body .dx-button-content .dx-icon {
  font-size: 22px !important;
  font-weight: bold !important;
}

/* .close-button {
  border: 1px solid #fff !important;
  color: #fff !important;
}

.close-button:hover {
  background: #fff !important;
  color: #000 !important;
} */

.form-read-only input,
.form-read-only textarea {
  background: #e5ecf3 !important;
  color: rgba(0, 0, 0, .87) !important;
}

.form-read-only::before {
  content: none !important;
}

.error-modal {
  margin-top: 3rem;
}

.error-modal .modal-header {
  gap: 0.7rem !important;
}

.error-modal i {
  font-size: 25px;
  color: #D84040;
}

.error-modal .modal-content {
  background: #F4F4F4;
  color: black !important;
}

.error-modal .modal-body p {
  word-wrap: break-word;
}

.nav-link {
  border: 1px solid rgb(0, 0, 0, 0.175) !important;
  border-bottom: none !important;
  background-color: white !important;
  color: black !important;
  /* border: 1px solid #0B1634 !important; */
}

.nav-link.active {
  background-color: #0B1634 !important;
  color: white !important;
  /* box-shadow: 0px 0px 3px 0px rgba(170,156,156,0.55) !important; */
}

.nav-link.active:hover {
  background-color: #0B1634 !important;
}

.nav-link:hover {
  /* border: 1px solid #0B1634 !important; */
  background-color: rgb(248, 249, 250) !important
}

#myTab {
  margin: 25px 0;
}

/* 
#myTab #tab-body, #myTab #tab-grid-body  {
  min-height: 500px !important;
  height: 500px !important;
}

#myTab #tab-grid-body .row, 
#myTab #tab-grid-body .card, 
#myTab #tab-grid-body .card-body, 
#myTab #tab-grid-body .dx-widget.dx-visibility-change-handler
 {
  height: 100% !important;
} 

#myTab #tab-grid-body .dx-datagrid-rowsview.dx-empty {
  min-height: 300px !important;
  height: 100% !important;
} */

.col-table {
  width: 90% !important;
}


.modal-backdrop {
  z-index: 2 !important;
}

.modal {
  z-index: 3 !important;
}

.react-confirm-alert-overlay {
  background: rgba(23, 24, 24, 0.9) !important;
}

#react-confirm-alert {
  z-index: 4;
}

.file-upload-modal input[type=file] {
  display: none;
}


.file-uploader {
  /* cursor: pointer; */
  z-index: 3 !important;
}

.file-uploader:focus-visible,
.file-uploader:focus-within {
  outline: none !important;
}

.file-upload-container {
  text-align: center;
  padding: 2.5rem 1rem 4rem !important;
}

.file-upload-body {
  width: fit-content;
  border-radius: 12px;
  border: 2px dashed #dee2e6;
  padding: 4rem 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.7rem;
  align-items: center;
  font-size: 1.2rem;
  color: var(--main-blue);
  text-align: center;
}

.file-upload-body button {
  cursor: pointer;
  z-index: 4 !important;
}

.file-form {
  text-align: start;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(2, 3fr);
  grid-template-rows: auto;
}

@media (max-width: 900px) {
  .file-form {
    grid-template-columns: repeat(1, 1fr);
  }
}

.selected-files {
  margin: 1rem 0;
  display: flex;
  gap: 1rem
}

.selected-files-item {
  display: flex;
  align-items: center;
  text-align: start;
  color: #0d6efd;
  border: 1px solid #dee2e6;
  border-radius: 12px;
  font-size: 1.1rem;
  padding: .5rem 0 .5rem 1rem;
}

.selected-files-item span {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 110px;
  height: 1.2em;
  white-space: nowrap;
}

.selected-files-item button {
  justify-self: end;
}

.selected-files-clear {
  color: black !important;
  font-weight: bold !important;
  border: 2px solid black !important;
  border-radius: 12px !important;
  font-size: 1.1rem !important;
  padding: .5rem 3.5rem !important;
  transition: all .3s ease;
}

.selected-files-clear:hover {
  background: black !important;
  color: white !important;
}


.file-preview .card {
  margin: 1rem 0;
}

.file-preview-img {
  transition: all .3s linear;
  display: block;
  margin: 0 auto;
  width: 100%;
  /* height: 150px; */
  object-fit: contain;
}

/* Inside menu */
.insideMenu .dx-item-content.dx-treeview-item-content,
.insideMenu .dx-item-content.dx-treeview-item-content span,
.insideMenu dx-treeview-node dx-treeview-item-without-checkbox {
  color: #000 !important;
}

/* .insideMenu .dx-treeview-item-without-checkbox[aria-expanded="true"]>.dx-item>.dx-item-content>span, .dx-treeview-item-without-checkbox[aria-expanded="true"]>.dx-item>.dx-item-content>i{
  color: #000 !important;
} */


.dx-popup-content {
  padding: 5px;
}

.modal-90w {
  max-width: 90% !important;
}

.modal-70w {
  max-width: 70% !important;
}

.dx-group-row {
  background: #eee !important;
}

.columnInput {
  background: red;
}

#tab-grid-body {
  padding: 0px !important;
}

.dx-popup-content {
  padding: 0px !important;
}

.clickable:hover {
  cursor: pointer;
  background-color: #ebebeb;
}

.insideMenu .active {
  background-color: #2fc2dd5c !important;
}

table .active td {
  background: #0d6efd !important;
  color: #fff !important;
}

/*Position the modals on the screen*/
.modalRight .modal-dialog {
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}


.modalRight .modal-header {
  height: 5vh !important;
}

.modalRight .modal-footer {
  height: 10vh !important;
}

.modalRight .modal-body {
  height: 85vh !important;
  overflow: auto !important;
}

.modalRight .modal-content {
  height: 100vh !important;
  border-radius: 0px !important;
}

.dx-button-mode-outlined .dx-icon {
  color: #fff !important;
}

/* Modal Custom Sizes*/
.modal-sm {
  min-width: 20vw !important;
}

/* .modal-md {
  min-width: 50vw !important;
}

.modal-lg {
  min-width: 75vw !important;
}

.modal-xl {
  min-width: 90vw !important;
} */

/* ALTERNATIVE THEME */


.altTheme .header-toolbar {
  background-image: none !important;
  background: #0d1b3b !important
}

.altTheme .btn-secondary {
  background: #0d1b3b !important;
  color: #fff !important;
}

.altTheme .btn-primary {
  background: #0d6efd !important;
  border-color: #0d6efd !important;
  color: #fff !important;
}

.altTheme .header-component .header-toolbar {
  background-color: #fff !important;

}

.altTheme .favorites-container,
.altTheme .favorites-container .favorites-item,
.dx-button-mode-outlined .dx-icon,
.altTheme .dx-button-text {
  color: #4B5675 !important;
}



/* ===== Scrollbar CSS ===== */
/* Firefox */
.dx-drawer-panel-content * {
  scrollbar-width: auto;
  scrollbar-color: #182852 #121e3e !important;
}

/* Chrome, Edge, and Safari */
.dx-drawer-panel-content *::-webkit-scrollbar {
  width: 10px !important;
}

.dx-drawer-panel-content *::-webkit-scrollbar-track {
  background: #182852 !important;
}

.dx-drawer-panel-content *::-webkit-scrollbar-thumb {
  background-color: #050e24 !important;
  border-radius: 10px !important;
  border: 2px solid #414141 !important;
}

#navigation-header {
  border-bottom: 1px solid #182852;
}

.modal-body {
  background: #f7f7f7 !important
}

.modal-title {
  font-size: 18px !important;
}

.grid {
  border-radius: 15px !important;
}

.modal-dialog {
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

/*Right*/
.modal.fade .modal-dialog {
  /* right: -320px; */
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.fade.in .modal-dialog {
  right: 0;
}

/* 
.nav-tabs .nav-link {
  height: 30px !important;
}

.nav-tabs .nav-link.active {
  height: 50px !important;
} */


/* @media (min-width: 576px) {
  .modal-sm {
    --bs-modal-width: 600px !important;
  }
} */

.modal .col {
  margin-top: 10px
}

.dx-texteditor.dx-editor-filled .dx-placeholder::before,
.dx-texteditor.dx-editor-filled .dx-texteditor-input,
.dx-texteditor.dx-editor-outlined .dx-placeholder::before,
.dx-texteditor.dx-editor-outlined .dx-texteditor-input {
  padding: 5px 16px 14px !important;
}



.headcol {
  position: absolute;
  width: 5em;
  left: 0;
  top: auto;
  border-top-width: 1px;
  /*only relevant for first row*/
  margin-top: -1px;
  /*compensate for top border*/
}

.headcol:before {
  content: 'Row ';
}

.scratch {
  text-decoration: line-through;
}

.modal-backdrop :last-child {
  z-index: 15 !important;
}

.reservationTabs .tab-pane {
  min-height: 400px !important;
}

.modal-backdrop {

  z-index: 3 !important;
}

.display-6 {
  font-size: 1.5rem !important;
}

/* overriding the default checkbox and radio button colors: */

.dx-checkbox-checked .dx-checkbox-icon {
  background-color: #0d6efd !important;
}

.dx-radiobutton-icon-checked {
  border-color: #0d6efd !important;
}

.dx-radiobutton-icon-checked .dx-radiobutton-icon-dot {
  background-color: #0d6efd !important;
}

.text-black-25 {
  color: rgba(0, 0, 0, 0.25) !important;
}